.image-text,
:--layout-dark .image-text,
:--group-dark .image-text {
  --color: var(--light);
  --color-rgb: var(--light-rgb);
}
:--layout-light .image-text,
:--group-light .image-text {
  --color: var(--dark);
  --color-rgb: var(--dark-rgb);
}

.image-text:first-child,
* + .image-text {
  margin-top: var(--section-gap);
}

.image-text__wrap {
  display: flex;
  gap: 64px;
}
.image-text--reverse .image-text__wrap {
  flex-direction: row-reverse;
}
@media (--page-max-width) {
  .image-text__wrap {
    gap: 48px;
  }
}
@media (--mobile) {
  .image-text__wrap,
  .image-text--reverse .image-text__wrap {
    flex-direction: column;
  }
  .image-text__wrap {
    gap: 32px;
  }
}

.image-text__wrap__text {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;

  width: 100%;
}

@media (--mobile) {
  .image-text .sup-title {
    text-align: center;
  }
}

.image-text__wrap__title {
  font-size: var(--heading-font-size);
  font-size: 26px;
  font-weight: 600;
  line-height: 130%;
  color: var(--color);
}
* + .image-text__wrap__title {
  margin-top: 8px;
}
@media (--tablet) {
  .image-text__wrap__title {
    font-size: 24px;
  }
}
@media (--mobile) {
  .image-text__wrap__title {
    font-size: 24px;
    text-align: center;
  }
}

.image-text__wrap__text hr {
  width: 100%;
  height: 1px;

  border: 0;
  background-image: linear-gradient(
    to right,
    rgba(var(--black-rgb), 0),
    rgba(164, 164, 164, 0.797),
    rgba(var(--black-rgb), 0)
  );
}
.image-text__wrap__text * + hr {
  margin-top: 24px;
}

.image-text__wrap__content {
  max-width: 50ch;

  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: var(--color);
}
* + .image-text__wrap__content {
  margin-top: 24px;
}
@media (--tablet) {
  .image-text__wrap__content {
    font-size: 16px;
  }
}
@media (--mobile) {
  .image-text__wrap__content {
    margin-right: auto;
    margin-left: auto;
    text-align: center;
  }
}

@media (--mobile) {
  .image-text__wrap__content ul {
    text-align: left;
  }
}

.image-text__wrap__content * + p,
.image-text__wrap__content * + ul {
  margin-top: 1em;
}

.image-text__wrap__content ul li {
  display: flex;
  align-items: center;
  gap: 8px;

  font-size: 15px;
  font-weight: 500;
  line-height: 1.3;
  letter-spacing: 0.03em;
}
.image-text__wrap__content ul * + li {
  margin-top: 16px;
}

.image-text__wrap__content ul li::before {
  --size: 20px;

  content: '';
  flex-shrink: 0;
  width: var(--size);
  height: var(--size);
  margin-top: 2px;

  background-image: url('/icons/red-check.svg');
  background-size: var(--size);
}

.image-text__wrap__content a {
  color: var(--primary-500);
  text-decoration: none;
}
.image-text__wrap__content a:hover {
  text-decoration: underline;
}

.image-text__wrap__content b {
  font-weight: 600;
  color: var(--primary-500);
}

.image-text__wrap__text__form .inline-form__input {
  max-width: 100%;
}

* + .image-text__wrap__text__cta {
  margin-top: 24px;
}
@media (--mobile) {
  .image-text__wrap__text__cta {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
