.text,
:--layout-dark .text,
:--group-dark .text {
  --color: var(--white);
  --color-rgb: var(--white-rgb);
}
:--layout-light .text,
:--group-light .text {
  --color: var(--black);
  --color-rgb: var(--black-rgb);
}

.text {
  --vertical-padding: 80px;
  --font-size: 18px;

  position: relative;

  color: var(--color);
}
.text:first-child,
* + .text {
  margin-top: var(--section-gap);
}
@media (--mobile) {
  .text {
    --font-size: 16px;
  }
}

.text__wrap {
  max-width: 930px;
  columns: 1;
  column-gap: 60px;
}
@media (--tablet) {
  .text__wrap {
    columns: 1;
  }
}

.text__wrap h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: 0.03em;
}
.text__wrap * + h2 {
  margin-top: 40px;
}
@media (--mobile) {
  .text__wrap h2 {
    font-size: 20px;
  }
  .text__wrap * + h2 {
    margin-top: 24px;
  }
}

.text__wrap p {
  font-size: var(--font-size);

  font-weight: 300;
  line-height: 1.5;
  letter-spacing: 0.01em;
  color: rgba(var(--white-rgb), 0.8);
}
.text__wrap * + p {
  margin-top: 16px;
}
.text__wrap h2 + p {
  margin-top: 18px;
}
@media (--mobile) {
  .text__wrap * + p {
    margin-top: 12px;
  }
}

.text__wrap b {
  color: var(--primary-500);
}

.text__wrap a {
  letter-spacing: 0.01em;
  color: var(--white);
  text-underline-offset: 0.2em;
  text-decoration-thickness: 0.08em;
}

.text__wrap * + ul {
  margin-top: 1em;
}
.text__wrap ul li {
  display: flex;
  gap: 24px;
}
.text__wrap ul li + li {
  margin-top: 16px;
}
.text__wrap ul li::before {
  content: '\2022';

  font-size: 40px;
  font-weight: bold;
  color: var(--primary-500);
  align-self: center;
}

.text__wrap__cta {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 64px;
}
