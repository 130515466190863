.top-formation {
  --color: var(--dark);
  --color-rgb: var(--dark-rgb);
}

.top-formation:first-child,
* + .top-formation {
  margin-top: var(--section-gap);
}
@media (--mobile) {
  .heading + .top-formation {
    margin-top: 24px;
  }
}

.top-formation__wrap {
  display: grid;
  overflow: hidden;
  grid-template-columns: repeat(2, 1fr);

  color: var(--color);

  border: 2px solid var(--primary-500);
  border-radius: 20px;
  background-color: #f3f4f8;
}
@media (--mobile) {
  .top-formation__wrap {
    grid-template-columns: 1fr;
  }
}

.top-formation__image {
  position: relative;

  width: 100%;
}
.top-formation__image img {
  display: block;
  width: 103%;
  height: 100%;
  object-fit: cover;
}

.top-formation__plus-sign {
  position: absolute;
  top: 16px;
  right: 16px;
}

.top-formation__text {
  padding: 24px 24px 24px 40px;
}
@media (--tablet) {
  .top-formation__text {
    padding: 24px;
  }
}
@media (--mobile) {
  .top-formation__text {
    padding: 16px;
  }
}

.top-formation__text__title {
  font-size: 26px;
  font-weight: 700;
  line-height: 130%;
}
.top-formation__text__title a {
  color: inherit;
  text-decoration: none;
}
.top-formation__text__title a::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;

  content: '';
}
* + .top-formation__text__title {
  margin-top: 12px;
}
@media (--mobile) {
  .top-formation__text__title {
    font-size: 22px;
  }
  .top-formation__text__title br {
    display: none;
  }
}

.top-formation__text__description {
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
  color: rgba(var(--color-rgb), 0.6);
}
* + .top-formation__text__description {
  margin-top: 12px;
}

.top-formation__text__price {
  display: flex;
  align-items: center;
  gap: 12px;
}
* + .top-formation__text__price {
  margin-top: 24px;
}

.top-formation__text__price strong {
  font-size: 30px;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: 0.01em;
}
@media (--mobile) {
  .top-formation__text__price strong {
    font-size: 24px;
  }
}

.top-formation__text__price strong sub {
  bottom: 0;

  font-size: 20px;
}

.top-formation__text__price__tag {
  display: inline-block;
  margin-top: 5px;
  padding: 2px 6px 6px 6px;

  font-size: 14px;
  font-weight: 400;
  line-height: 100%;
  color: var(--light);

  border-radius: 999px;
  background-color: var(--primary-500);
  text-align: left;
}

.top-formation__text__sub-price {
  font-size: 24px;
  font-weight: 500;
  line-height: 130%;
  letter-spacing: 0.01em;
  color: var(--primary-500);
}
* + .top-formation__text__sub-price {
  margin-top: 8px;
}
@media (--mobile) {
  .top-formation__text__sub-price {
    font-size: 18px;
  }
}

.top-formation__text__button {
  text-align: center;
}
* + .top-formation__text__button {
  margin-top: 24px;
}

.top-formation__text__button .button {
  width: 100%;
}
.top-formation__wrap:hover .button {
  --background-color: var(--primary-400);
  --color: var(--white);
}
