.landing-heading,
:--layout-dark .landing-heading,
:--group-dark .landing-heading {
  --color: var(--white);
  --color-rgb: var(--white-rgb);
}
:--layout-light .landing-heading,
:--group-light .landing-heading {
  --color: var(--black);
  --color-rgb: var(--black-rgb);
}

.landing-heading:first-child,
* + .landing-heading {
  margin-top: var(--section-gap);
}

.landing-heading__title {
  font-size: var(--heading-font-size);

  font-weight: 700;
  line-height: 1.3;
  letter-spacing: 0.01em;
  color: var(--color);
  text-align: center;
}
.landing-heading__title strong {
  color: var(--primary-500);
}
@media (--mobile) {
  .landing-heading__title {
    line-height: 1.4;
  }
}

.landing-heading__sub-title {
  font-size: 24px;

  font-weight: 300;
  line-height: 1.3;
  letter-spacing: 0.01em;
  color: var(--color);
  text-align: center;
}
* + .landing-heading__sub-title {
  margin-top: 8px;
}
@media (--tablet) {
  .landing-heading__sub-title {
    font-size: 24px;
  }
}
@media (--mobile) {
  .landing-heading__sub-title {
    font-size: 16px;
  }
}
