.team-text,
:--layout-dark .team-text,
:--group-dark .team-text {
  --color: var(--white);
  --color-rgb: var(--white-rgb);
}
:--layout-light .team-text,
:--group-light .team-text {
  --color: var(--black);
  --color-rgb: var(--black-rgb);
}

* + .team-text {
  margin-top: var(--section-gap);
}

.team-text {
  margin-top: var(--section-gap);
}

.team-text__description {
  max-width: 771px;
  margin: 0 auto;

  color: rgba(var(--color-rgb), 0.8);
  text-align: center;
}

.team-text__description h2 {
  font-size: var(--heading-font-size);
  font-weight: 700;
  line-height: 130%; /* 52px */
  letter-spacing: 0.06em;

  text-align: center;
}

.team-text__description p {
  margin-top: 32px;

  font-size: 22px;
  font-weight: 300;
  line-height: 140%; /* 33.6px */
  letter-spacing: 0.02em;

  color: rgba(255, 255, 255, 0.8);

  text-align: center;
}

.team-text__pictures {
  display: flex;
  justify-content: center;
  margin-top: 64px;
}

.team-text__pictures__image {
  position: relative;

  width: 120px;
  height: 120px;
  margin-right: -20px;

  /* Ajustez cette valeur selon votre préférence */
}

.team-text__pictures__image:last-child {
  margin-right: 0;
}

.team-text__pictures__image__img {
  position: absolute;

  width: 100%;
  height: 100%;

  border: 5px solid #fff;

  border-radius: 50%;
  object-fit: cover;
}

.team-text__pictures__company {
  position: absolute;
  bottom: 0;
  left: 0;

  width: 45%;
  height: 45%;
}

.team-text__pictures__company img {
  position: absolute;

  width: 100%;
  height: 100%;

  object-fit: contain;
}

.team-text__text {
  margin-top: 24px;

  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.06em;
  color: rgba(var(--color-rgb), 0.8);

  text-align: center;
}

@media (--mobile) {
  .team-text__text {
    font-size: 18px;
  }
}

@media (--mobile) {
  * + .team-text {
    margin-top: 64px;
  }

  .team-text__description h2 {
    font-size: 24px;
    line-height: 130%; /* 41.6px */
    letter-spacing: 0.08em;
  }

  .team-text__description p {
    margin-top: 24px;

    font-size: 16px;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.02em;
  }

  .team-text__pictures__image {
    width: 80px;
    height: 80px;
    margin-right: -20px; /* Ajustez cette valeur selon votre préférence */
  }

  .team-text__description__circles {
    margin-top: 24px;
  }

  .team-text__pictures {
    display: flex;
    justify-content: center;
    margin-top: 32px;
  }
}
