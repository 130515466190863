.qa,
:--layout-dark .qa,
:--group-dark .qa {
  --color: var(--white);
  --color-rgb: var(--white-rgb);
  --number-color: var(--white);
}
:--layout-light .qa,
:--group-light .qa {
  --color: var(--black);
  --color-rgb: var(--black-rgb);
  --number-color: var(--primary-500);
}

* + .qa {
  margin-top: var(--section-gap);
}
.qa__title + .qa__content {
  margin-top: 20px;
}
@media (--tablet) {
  .qa__title + .qa__content {
    margin-top: 6px;
  }
}

.qa__content {
  overflow: hidden;
  width: 100%;

  border-radius: 16px;
  text-align: start;
}
.qa__content,
:--layout-dark .qa__content,
:--group-dark .qa__content {
  border: 1px solid rgba(var(--color-rgb), 0.2);
  background: linear-gradient(
      213deg,
      rgba(96, 150, 255, 0.1) 10%,
      rgba(96, 150, 255, 0) 80%
    ),
    linear-gradient(
      150deg,
      rgba(var(--black-rgb), 0.1),
      rgba(140, 140, 140, 0.1)
    );
}
:--layout-light .qa__content,
:--group-light .qa__content {
  border: none;
  background: linear-gradient(
      213deg,
      rgba(162, 193, 255, 0.1) 10%,
      rgba(162, 193, 255, 0) 80%
    ),
    linear-gradient(
      150deg,
      rgba(var(--white-rgb), 0.1),
      rgba(140, 140, 140, 0.1)
    );
}
@media (--tablet) {
  .qa__content {
    width: 100%;
  }
}

.qa__content__item {
  position: relative;

  color: var(--color);
  user-select: none;
}

.qa__content__item--odd {
  background: rgba(var(--color-rgb), 0.05);
}

.qa__content__item__header {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 24px 40px;

  transition: opacity 100ms;
}
@media (--mobile) {
  .qa__content__item__header {
    padding: 16px 16px;
  }
}

.qa__content__item__header:hover {
  opacity: 0.8;
}

.qa__content__item__header--no-qa:hover {
  opacity: 1;
}

.qa__content__item__header__title__link:hover {
  text-decoration: none;
}

.qa__content__item__header__title {
  display: flex;
  align-items: center;
  gap: 24px;

  font-size: 18px;
  font-weight: 700;
  line-height: 1.4;
}
.qa__content__item__header__title * {
  font-weight: inherit;
}
@media (--tablet) {
  .qa__content__item__header__title {
    gap: 16px;

    font-size: 16px;
  }
}

.qa__content__item__header__title__number {
  font-size: 32px;

  font-weight: 600;
  line-height: 100%;
  color: var(--number-color);
}
@media (--tablet) {
  .qa__content__item__header__title__number {
    font-size: 24px;
  }
}

.qa__content__item__header__title__link {
  text-decoration: none;
}
@media (--mobile) {
  .qa__content__item__header__title__link button {
    text-align: start;
  }
}

.qa__content__item__header__title__link button {
  line-height: inherit;
  color: var(--color);
  text-decoration: none;
  cursor: pointer;
  text-align: left;
}

.qa__content__item__header__title__link button::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;

  content: '';

  opacity: 0.5;
}

.qa__content__item__header__icon svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.qa__content__item__content {
  overflow-y: hidden;

  padding-right: 40px;
  padding-left: 40px;

  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
}
@media (--mobile) {
  .qa__content__item__content {
    padding: 0;
  }
}

.qa__content__item__content__inner {
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  gap: 32px;
  padding-bottom: 16px;
}

@media (--tablet) {
  .qa__content__item__content__inner {
    grid-template-columns: 1fr;
    gap: 16px;
    padding-right: 16px;
    padding-bottom: 24px;
    padding-left: 16px;
  }
}

@media (--mobile) {
  .qa__content__item__content__inner {
    padding-bottom: 32px;
  }
}

.qa__content__item__content__image {
  overflow: hidden;
  width: 316px;
  height: 100%;

  border-radius: 8px;
}

.qa__content__item__content__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.qa__content__item__content p {
  line-height: 1.5;
}

.qa__content__item__content * + p,
.qa__content__item__content * + ul {
  margin-top: 1em;
}

.qa__content__item__content ul * + li {
  margin-top: 0.5em;
}

.qa__content__item__content ul li {
  margin-left: 32px;
}

.qa__content__item__content a {
  color: var(--primary-500);
  text-decoration: none;
}
.qa__content__item__content a:hover {
  text-decoration: underline;
}

@media (--mobile) {
  .qa__content__item__header__icon svg {
    width: 18px;
    height: 18px;
  }
}

@media (--tablet) {
  .qa__content__item__content__image {
    width: 100%;
    height: 100px;
    order: 1;
  }
}
