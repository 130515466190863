.tarte:first-child,
* + .tarte {
  margin-top: var(--section-gap);
}

.tarte__wrap {
  --gap: 52px;

  padding: 40px 24px;

  border-radius: 24px;
  background: radial-gradient(
      50% 85% at 50% 100%,
      rgba(var(--white-rgb), 0.3) 0%,
      rgba(var(--white-rgb), 0) 100%
    ),
    #201d40;
  align-self: stretch;
  text-align: center;
}
@media (--mobile) {
  .tarte__wrap {
    --gap: 32px;

    padding: 24px 16px;
  }
}

.tarte__title {
  font-size: 32px;
  font-weight: 700;
  line-height: 1.3;
  letter-spacing: 0.01em;
  color: var(--white);
}
@media (--tablet) {
  .tarte__title {
    font-size: 24px;
  }
}
@media (--mobile) {
  .tarte__title {
    font-size: 20px;
  }
}

.tarte__items {
  display: flex;
  justify-content: center;
  gap: 64px;
}
* + .tarte__items {
  margin-top: var(--gap);
}
@media (--tablet) {
  .tarte__items {
    gap: 32px;
  }
}
@media (--mobile) {
  .tarte__items {
    flex-direction: column;
    gap: 16px;
  }
}

.tarte__item {
}

.tarte__item__label {
  --size: 54px;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: var(--size);
  height: var(--size);
  margin-right: auto;
  margin-left: auto;
  padding: 6px 6px 10px 6px;

  font-size: 26px;
  font-weight: 800;
  color: #201d40;

  border-radius: 999px;
  background: var(--primary-500);
  user-select: none;
}
@media (--mobile) {
  .tarte__item__label {
    --size: 42px;

    font-size: 20px;
  }
}

.tarte__item__title {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.4;
  color: var(--white);
}
* + .tarte__item__title {
  margin-top: 6px;
}
@media (--mobile) {
  .tarte__item__title {
    font-size: 17px;
  }
}

.tarte__footer {
  margin-top: 24px;

  font-size: 16px;
  font-weight: 500;
  line-height: 1.3;
  letter-spacing: 0.01em;
  color: var(--white);
}
* + .tarte__footer {
  margin-top: var(--gap);
}
