.timeline {
  --item-height: 210px;
  --bullet-size: 21px;
  --gap: 20px;
  padding-top: var(--section-gap);
}

@media (--mobile) {
  .timeline {
    padding-top: 32px;
  }
}

.timeline__desktop {
  display: block;
}

.timeline__mobile {
  display: none;
}

@media (--tablet) {
  .timeline__desktop {
    display: none;
  }

  .timeline__mobile {
    display: block;
  }
}

.timeline__desktop img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.timeline__wrapper {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.timeline__middle-line {
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 0;

  width: 100%;
  height: 1px;

  background: rgb(255 255 255 / 40%);
}

.timeline__list {
  position: relative;
  z-index: 1;

  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;

  color: var(--white);
}

.timeline__list--odd {
  display: flex;
  gap: var(--gap);
  align-self: baseline;
}

.timeline__list--even {
  display: flex;
  gap: var(--gap);

  width: 100%;
  margin-left: 30.2%;
}

.timeline__item {
  position: relative;

  display: flex;
  gap: 118px;
}

.timeline__item__line {
  position: absolute;
  top: 0;
  left: 33.2%;
  z-index: 0;

  width: 1px;
  height: 100%;

  background: rgb(255 255 255 / 40%);
}

.timeline__item--even {
  flex-direction: column-reverse;
  max-width: fit-content;
}

.timeline__item--odd {
  flex-direction: column;
}

.timeline__item__top {
  z-index: 1;

  display: flex;

  gap: 25px;
}

.timeline__item--even .timeline__item__top {
  align-items: flex-end;
}

.timeline__item__number {
  font-family: var(--font-family);
  font-size: 40px;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: 0.06em;
}

.timeline__item__content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.timeline__item__title {
  max-width: 11ch;

  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 700;
  line-height: 110%;
  letter-spacing: 0.06em;
}

.timeline__item__date {
  font-family: var(--font-family);
  font-size: 20px;

  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0.06em;
  color: #e26864;
}

.timeline__item__separator {
  width: 10px;
  height: 10px;

  border-radius: 50%;

  background-color: var(--white);
}

.timeline__middle-line::before {
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 2;

  content: '';

  width: 120px;
  height: 10px;

  background-color: var(--theme-black);

  transform: translate(-50%, -50%);
}

.timeline__item__bullet,
.timeline__final__bullet {
  width: 55px;
  height: 55px;

  border-radius: 50%;
}

.timeline__item__bullet {
  position: relative;
  z-index: 1;

  background-color: var(--white);
}

.timeline__item--even .timeline__item__bullet {
  position: absolute;
  top: calc((var(--item-height) / 2) - 55px);

  right: 0;

  transform: translateX(calc(var(--bullet-size) / 2));
}

.timeline__item--odd .timeline__item__bullet {
  position: absolute;
  top: calc((var(--item-height) / 2) - 55px);

  left: 0;

  transform: translateX(calc(-1 * var(--bullet-size) / 2));
}

.timeline__final__bullet img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.timeline__final {
  z-index: 1;

  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  width: fit-content;
  margin: auto;

  color: var(--white);

  background-color: var(--theme-black);
}

.timeline__final__content h3 {
  max-width: 12ch;
  padding-left: 28px;

  font-size: 20px;

  font-weight: 700;

  line-height: 100%;
  letter-spacing: 0.06em;
}

@media (--tablet) {
  .timeline__wrapper {
    flex-direction: column;
    gap: var(--gap);
  }
}

@media (--tablet) {
  .timeline__middle-line {
    top: calc((var(--item-height) / 2) - 45px);
    left: 50%;
    z-index: 0;

    width: 1px;
    height: 90%;
  }
}

@media (--tablet) {
  .timeline__list {
    flex-direction: row-reverse;
    align-items: flex-start;
    justify-content: space-between;
  }
}

@media (--tablet) {
  .timeline__list--odd {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    width: 100%;
  }
}

@media (--tablet) {
  .timeline__list--even {
    display: flex;
    flex-direction: column;

    align-items: flex-start;

    width: 100%;
    margin-top: calc((var(--item-height) / 2) - 1px);
    margin-left: unset;
  }
}

@media (--tablet) {
  .timeline__item {
    flex-direction: row;
    gap: 0;
    min-height: var(--item-height);
  }
}

@media (--tablet) {
  .timeline__item__line {
    top: calc((var(--item-height) / 2) - 45px);
    left: 0;
    z-index: 0;

    width: 100%;
    height: 1px;
  }
}

@media (--tablet) {
  .timeline__item--odd {
    width: 100%;
  }

  .timeline__item--even {
    width: 100%;
    max-width: unset;
  }

  .timeline__item__top {
    flex-direction: column;
    gap: 16px;
  }

  .timeline__item--odd .timeline__item__top {
    align-items: flex-end;
    text-align: right;
  }

  .timeline__item--even .timeline__item__top {
    align-items: flex-start;
    text-align: left;
  }
}

@media (--tablet) {
  .timeline__middle-line::before {
    top: 0;
    left: 50%;
    z-index: 2;

    width: 0;
    height: 0;

    background-color: var(--theme-black);

    transform: translate(-50%, -50%);
  }
}

@media (--tablet) {
  .timeline__final__bullet {
    width: 42px;
    height: 42px;

    box-shadow: 0 0 105px 15px rgba(45, 255, 196, 0.9);
  }
}

@media (--tablet) {
  .timeline__item__bullet {
    width: var(--bullet-size);
    height: var(--bullet-size);
  }
}

/* @media (--tablet) {
  .timeline__item--even .timeline__item__bullet {
    position: relative;

    top: 100%;
    top: calc((55px / 2) - 24px);
    z-index: 1;
    transform: none;

    transform: translateY(100%);
  }
} */

/* @media (--tablet) {
  .timeline__item--odd .timeline__item__bullet {
    position: relative;
    bottom: calc((5px / 2) - 70px);
    left: calc((42px / 2) - 42px);
    z-index: 1;

    transform: translateY(-50%);
  }
} */

@media (--tablet) {
  .timeline__final {
    flex-direction: column;
    align-items: center;
  }
}

@media (--tablet) {
  .timeline__final__content {
    margin: auto;
    padding-top: 16px;
    padding-left: 0;
  }
}

@media (--tablet) {
  .timeline__item--even {
    flex-direction: row;
  }
}

@media (--tablet) {
  .timeline__item--odd {
    flex-direction: row-reverse;
  }
}
