* + .landing-modules {
  margin-top: 72px;
}
@media (--mobile) {
  * + .landing-modules {
    margin-top: 20px;
  }
}

.landing-modules__item__grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 20px;
  margin-top: 20px;
}

.landing-modules__item {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;

  overflow: hidden;
  height: 261px;
  padding: 32px 16px;

  border-radius: 7px;

  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.05);
  background: var(--primary-500);
  backdrop-filter: blur(2.5px);
}
.landing-modules__item-odd {
  background: var(--white);
}

.landing-modules__item__background {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  width: 100%;
  height: 100%;
  transform: translateY(-30%);
}

.landing-modules__item__background img {
  width: 100%;
  height: 100%;
}

.landing-modules__item__logo {
  width: 95px;
  height: 22.844px;
}
.landing-modules__item__logo img {
  width: 100%;
  height: 100%;
}

.landing-modules__item__text {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.landing-modules__item__text__date {
  font-size: 14px;

  font-weight: 500;
  line-height: 1.3; /* 18.2px */
  letter-spacing: 0.01em;
  color: #531513;
  text-transform: uppercase;
}

.landing-modules__item__text p {
  font-size: 16px;

  font-weight: 800;
  line-height: 1.3;
  letter-spacing: 0.01em;
  color: var(--white);
}
.landing-modules__item-odd .landing-modules__item__text p {
  color: var(--primary-500);
}
