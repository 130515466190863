.questions-cards,
:--layout-dark .questions-cards,
:--group-dark .questions-cards {
  --color: var(--light);
  --color-rgb: var(--white-rgb);
}
:--layout-light .questions-cards,
:--group-light .questions-cards {
  --color: var(--black);
  --color-rgb: var(--black-rgb);
}

*:not(.hero) + .questions-cards {
  margin-top: 64px;
}

.questions-cards {
  color: var(--color);
}

.questions-cards__card {
  height: fit-content;

  border-radius: 16px;
  background: #f3f4f8;
}

.questions-cards__content {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(3, 1fr);
  gap: 32px;
  padding: 0 62px;
}

@media (--tablet) {
  .questions-cards__content {
    grid-template-columns: repeat(2, 1fr);
    padding: 0 32px;
  }
}
@media (--mobile) {
  .questions-cards__content {
    grid-template-columns: 1fr;

    padding: 0;
  }
}

.questions-cards__card__client__container {
  padding: 24px;
}

.questions-cards__card__client {
  display: flex;
}

.questions-cards__card__client__picture {
  width: 53px;
  height: 52px;

  border-radius: 10px;
}

.questions-cards__card__client__infos {
  width: 100%;
  margin-left: 16px;
  align-self: center;
}

.questions-cards__card__client__infos__container {
  display: flex;
}

.questions-cards__card__client__infos__name {
  font-family: var(--font-family);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  color: var(--dark);
}

.questions-cards__card__client__infos__member-since {
  font-family: var(--font-family);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  color: rgba(var(--dark-rgb), 0.5);
}

.questions-cards__card__client__question-sent {
  margin-left: auto;

  font-family: var(--font-family);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: 0.01em;
  color: #000;
  color: rgba(var(--dark-rgb), 0.5);
}

* + .questions-cards__card__content {
  margin-top: 16px;
}

.questions-cards__card__content__question {
  font-family: var(--font-family);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  color: var(--dark);
}

.questions-cards__card__content__question strong {
  font-family: var(--font-family);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  color: var(--primary-500);
}

.questions-cards__card__content__actions {
  display: flex;
  gap: 9px;

  font-size: 12px;
  font-weight: 500;
  line-height: 1.3;
  letter-spacing: 0.01em;
  color: rgba(var(--color-rgb), 0.5);
}
* + .questions-cards__card__content__actions {
  margin-top: 16px;
}

.questions-cards__card__footer {
  position: relative;
}

.questions-cards__card__footer__image-container {
  overflow: hidden;
  width: 100%;
  height: 100%;

  border-radius: 11px;
}

.questions-cards__card__footer__image {
  width: 100%;
  height: 100%;

  object-fit: cover;
}

.questions-cards__card__footer__background {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  overflow: hidden;

  width: 100%;
  height: 100%;

  border-radius: 0 0 16px 16px;
}

.questions-cards__card__footer__background img {
  overflow: auto;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.questions-cards__card__footer__container {
  position: relative;
  z-index: 2;

  display: flex;
  overflow: hidden;
  gap: 17px;
  padding: 24px;
}
