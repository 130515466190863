.slider-team__item {
  --width: 290px;

  position: relative;

  flex: 0 0 var(--width);
  overflow: hidden;
  width: var(--width);
  height: 390px;

  border-radius: 16px;
  background-color: black;
}
@media (--mobile) {
  .slider-team__item {
    --width: 240px;

    height: 320px;
  }
}

.slider-team__item::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;

  content: '';

  background: linear-gradient(
    180deg,
    rgba(var(--black-rgb), 0) 60%,
    rgba(var(--black-rgb), 0.6) 90%
  );
}

.slider-team__item__picture {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  object-fit: cover;
}

.slider-team__item__content {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
  padding: 20px;
}
@media (--mobile) {
  .slider-team__item__content {
    padding: 16px;
  }
}

.slider-team__item__text-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
@media (--mobile) {
  .slider-team__item__text-content {
    gap: 4px;
  }
}

.slider-team__item__text__title {
  font-size: 18px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: 0.06em;
}
@media (--mobile) {
  .slider-team__item__text__title {
    font-size: 16px;
  }
}

.slider-team__item__text__role {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 0.06em;
  color: rgba(var(--white-rgb), 0.8);
}
@media (--mobile) {
  .slider-team__item__text__role {
    font-size: 13px;
  }
}

.slider-team__item__logo {
  --size: 60px;

  width: var(--size);
  height: var(--size);

  border-radius: 16px;
}
@media (--mobile) {
  .slider-team__item__logo {
    --size: 52px;
  }
}
