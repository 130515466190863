* + .landing-testimonials {
  margin-top: 72px;
}
@media (--tablet) {
  * + .landing-testimonials {
    margin-top: 48px;
  }
}
@media (--mobile) {
  * + .landing-testimonials {
    margin-top: 20px;
  }
}

.landing-testimonials__grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: var(--landing-testimonials-gap);
}
@media (--tablet) {
  .landing-testimonials__grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (--mobile) {
  .landing-testimonials__grid {
    --gap: 16px;

    grid-template-columns: 1fr;
  }
}

.landing-testimonials__item {
  --border-radius: 16px;

  position: relative;

  display: flex;
  flex-direction: column;

  justify-content: space-between;

  padding: 24px;

  color: #000;

  border: 1px solid #d5d5d5;

  border-radius: var(--border-radius);

  background: linear-gradient(
    252deg,
    rgba(255, 255, 255, 0.9) 4.3%,
    rgba(255, 255, 255, 0.66) 87.24%
  );
}
@media (--mobile) {
  .landing-testimonials__item {
    padding: 16px;
  }
}

.landing-testimonials__item::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  content: '';

  border: 1px solid transparent;
  border-radius: var(--border-radius);
  background: linear-gradient(
      135deg,
      rgb(var(--white-rgb), 0.2),
      rgb(var(--white-rgb), 0)
    )
    border-box;

  pointer-events: none;
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.landing-testimonials__quote {
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0.01em;
  color: #000;
}
* + .landing-testimonials__quote {
  margin-top: 18px;
}
@media (--tablet) {
  * + .landing-testimonials__quote {
    margin-top: 16px;
  }
}
@media (--mobile) {
  .landing-testimonials__quote {
    font-size: 16px;
  }
  * + .landing-testimonials__quote {
    margin-top: 12px;
  }
}

.landing-testimonials__author {
  display: flex;
  align-items: center;
  gap: 16px;
}
* + .landing-testimonials__author {
  margin-top: 24px;
}
@media (--mobile) {
  .landing-testimonials__author {
    gap: 12px;
  }
  * + .landing-testimonials__author {
    margin-top: 16px;
  }
}

.landing-testimonials__author__headshot {
  --size: 50px;

  position: relative;

  flex-shrink: 0;
  overflow: hidden;

  width: var(--size);
  height: var(--size);

  border: 1px solid rgba(0, 0, 0, 0%);

  border-radius: 50%;
}
@media (--mobile) {
  .landing-testimonials__author__headshot {
    --size: 40px;
  }
}

.landing-testimonials__author__headshot::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  content: '';

  border: 1px solid transparent;
  border-radius: var(--border-radius);
  background: linear-gradient(
      50deg,
      rgba(255, 255, 255, 20%),
      rgba(255, 255, 255, 0%)
    )
    border-box;

  pointer-events: none;
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.landing-testimonials__author__headshot img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.landing-testimonials__author__text {
  font-size: 14px;

  font-weight: 400;
  line-height: 130%;
  letter-spacing: 0.01em;
  color: rgba(0, 0, 0, 70%);
}
@media (--mobile) {
  .landing-testimonials__author__text {
    font-size: 14px;
  }
}

.landing-testimonials__author__text b {
  font-weight: 400;
  color: #000;
}
