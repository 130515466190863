.join__content {
  --width: 380px;

  position: relative;

  flex: 0 0 var(--width);
  overflow: hidden;
  width: var(--width);

  border: 1px solid rgba(var(--white-rgb), 0);
  border-radius: 16px;
  background: linear-gradient(
    308deg,
    rgba(var(--white-rgb), 0.1) 64.65%,
    rgba(var(--white-rgb), 0.02) 91.25%
  );
}
.join__content::before {
  position: absolute;

  content: '';
  padding: 1px;

  border-radius: inherit;
  background: linear-gradient(
    102deg,
    rgba(var(--white-rgb), 0) 2.32%,
    rgba(var(--white-rgb), 0.26) 31.65%,
    rgba(var(--white-rgb), 0) 60.99%,
    rgba(var(--white-rgb), 0) 60.99%
  );
  pointer-events: none;
  inset: 0;
  mask:
    conic-gradient(#000 0 0) content-box exclude,
    conic-gradient(#000 0 0);
}
@media (--tablet) {
  .join__content {
    --width: 310px;
  }
}
@media (--mobile) {
  .join__content {
    flex: auto;
    width: 100%;
  }
}

.join__content__wrap {
  padding: 40px 16px 40px 16px;
}
@media (--tablet) {
  .join__content__wrap {
    padding: 32px 12px 32px 12px;
  }
}
@media (--mobile) {
  .join__content__wrap {
    padding: 24px 12px 24px 12px;
  }
}

.join__sup-title {
  font-size: 13px;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: 0.01em;
  color: #5e95ff;
  text-align: center;
}

.join__price {
  font-family: var(--font-family-title);
  font-size: 16px;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: 0.01em;
  color: rgba(var(--color-rgb), 0.7);
  text-align: center;
}
.join__price b {
  font-size: 32px;
  font-weight: 700;
  color: var(--color);
}
* + .join__price {
  margin-top: 20px;
}

.join__description {
  padding: 0 16px;

  font-size: 16px;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: 0.01em;
  color: var(--color);
  text-align: center;
}
* + .join__description {
  margin-top: 20px;
}

.join__description b {
  font-weight: 400;
  color: #5e95ff;
}

.join__description i {
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
}

.join__description a {
  color: inherit;
  text-underline-offset: 0.1em;
  text-decoration-thickness: 0.05em;
}

.join__description * + p,
.join__description * + ul,
.join__description * + ol {
  margin-top: 1em;
}

.join__content__footer {
  padding: 0 16px;

  font-size: 13px;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: 0.01em;

  background: rgba(var(--white-rgb), 0.1);
  text-align: center;
}

.join__content__footer b {
  font-weight: 700;
}

.join__list__show-more {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  width: 100%;
  padding: 24px 0 28px 0;

  font-size: 14px;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: 0.01em;
  color: var(--color);
  cursor: pointer;
}
@media (--mobile) {
  .join__list__show-more {
    padding: 18px 0 20px 0;
  }
}

.join__list__show-more img {
  position: relative;
  top: 1.5px;

  display: block;
  flex-shrink: 0;
  width: 20px;
  height: auto;
  transition: transform 200ms;
}
.join__list__show-more--reverse img {
  transform: rotate(180deg);
}

.join__list__ul {
  --gap: 24px;

  position: relative;

  display: flex;
  flex-direction: column;
  gap: var(--gap);
  padding-bottom: 40px;
  list-style: none;
}
@media (--mobile) {
  .join__list__ul {
    padding-bottom: 28px;
  }
}

.join__list__item {
  display: flex;
  align-items: center;
  gap: 8px;

  font-size: 13px;
  font-weight: 300;
  line-height: 130%;
  letter-spacing: 0.01em;
  color: var(--color);
  text-align: left;
}
.join__list__item img {
  flex-shrink: 0;
  width: 20px;
  height: auto;
}
