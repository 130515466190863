.offers-cards,
:--layout-dark .offers-cards,
:--group-dark .offers-cards {
  --color: var(--light);
  --color-rgb: var(--white-rgb);
}
:--layout-light .offers-cards,
:--group-light .offers-cards {
  --color: var(--black);
  --color-rgb: var(--black-rgb);
}

* + .offers-cards {
  margin-top: var(--section-gap);
}
.heading + .offers-cards {
  margin-top: 32px;
}

.offers-cards {
  color: var(--color);
}

.offers-cards__content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
}
@media (--mobile) {
  .offers-cards__content {
    grid-template-columns: 1fr;
    gap: 32px;
  }
}

.offers-cards__card {
  position: relative;

  height: fit-content;
  padding: 40px 24px;

  border: 1px solid rgba(255, 255, 255, 0.2);
}
@media (--mobile) {
  .offers-cards__card {
    padding: 20px 16px;
  }
  .offers-cards__card--primary-black--is-featured {
    padding-top: 40px;
  }
}

.offers-cards__card__featured {
  position: absolute;

  top: 0;
  left: 50%;

  width: fit-content;

  padding: 12px 16px;

  font-family: var(--font-family-text);
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: 0.01em;
  color: var(--black);

  border-radius: 50px;
  background: var(--color-theme);
  transform: translate(-50%, -50%);
}

.offers-cards__card__icon {
  font-size: 24px;
  text-align: center;
}

.offers-cards__card--blue {
  --color-theme: var(--blue);
  border-radius: 8px;
  background: linear-gradient(
      213deg,
      rgba(96, 150, 255, 0.16) 4.4%,
      rgba(96, 150, 255, 0) 87.78%
    ),
    linear-gradient(
      150deg,
      rgba(0, 0, 0, 0.1) 9.49%,
      rgba(140, 140, 140, 0.1) 93.56%
    );

  backdrop-filter: blur(14px);
}

.offers-cards__card--green {
  --color-theme: var(--green);
  border-radius: 8px;

  background: linear-gradient(
      213deg,
      rgba(92, 244, 89, 0.15) 4.4%,
      rgba(92, 244, 89, 0) 87.78%
    ),
    linear-gradient(
      150deg,
      rgba(0, 0, 0, 0.1) 9.49%,
      rgba(140, 140, 140, 0.1) 93.56%
    );

  backdrop-filter: blur(10px);
}

.offers-cards__card--primary-black {
  --color-theme: var(--primary-500);
  border-radius: 8px;

  background: linear-gradient(
      213deg,
      rgba(251, 112, 107, 0.16) 4.4%,
      rgba(251, 112, 107, 0) 87.78%
    ),
    linear-gradient(
      150deg,
      rgba(0, 0, 0, 0.1) 9.49%,
      rgba(140, 140, 140, 0.1) 93.56%
    );

  backdrop-filter: blur(10px);
}

.offers-cards__card__title {
  font-family: var(--font-family-text);

  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: #fff;

  text-align: center;
}

* + .offers-cards__card__title {
  margin-top: 16px;
}

.offers-cards__card__featured + .offers-cards__card__title {
  margin-top: -40px;
}

.offers-cards__card__title-description {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: 0.02em;
  color: var(--color-theme);

  text-align: center;
}

* + .offers-cards__card__title-description {
  margin-top: 16px;
}

.offers-cards__card__price {
  font-family: var(--font-family-text);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;

  letter-spacing: 0.01em;
  color: rgba(255, 255, 255, 0.7);

  text-align: center;
}

.offers-cards__card__price strong {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: 0.03em;
  color: #fefefe;
}

* + .offers-cards__card__price {
  margin-top: 24px;
}

.offers-cards__card__teaser {
  font-family: var(--font-family-text);
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 130%;
  letter-spacing: 0.01em;

  color: rgba(254, 254, 254, 0.9);
  text-align: center;
}

* + .offers-cards__card__teaser {
  margin-top: 16px;
}

.offers-cards__card__engagement {
  margin-top: 12px;

  font-family: var(--font-family-text);
  font-size: 15px;
  font-weight: 300;
  letter-spacing: 0.14px;
  color: rgba(255, 255, 255, 0.7);

  text-align: center;
}

.offers-cards__card__engagement + .offers-cards__card__button__cta {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}

.offers-cards__card__button__cta {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
}

* + .offers-cards__card__button__cta {
  margin-top: 24px;
}

.offers-cards__card__offer-description {
  font-family: var(--font-family-text);
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 130%;
  letter-spacing: 0.01em;
  color: rgba(254, 254, 254, 0.7);

  text-align: center;
}

* + .offers-cards__card__offer-description {
  margin-top: 24px;
}

.offers-cards__card__info-title {
  margin-top: 32px;

  font-family: var(--font-family-text);

  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 130%;
  letter-spacing: 0.01em;
  color: #fefefe;
}

.offers-cards__card__info-title strong {
  font-weight: 700;
}

.offers-cards__card__offers {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
}

.offers-cards__card__offer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.offers-cards__card__offer + .offers-cards__card__offer {
  margin-top: 24px;
}

.offers-cards__card__offer-text {
  font-family: var(--font-family-text);
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 130%;
  letter-spacing: 0.01em;
  color: #fefefe;
}
