.unlock,
:--layout-dark .unlock,
:--group-dark .unlock {
  --color: var(--white);
  --color-rgb: var(--white-rgb);
}
:--layout-light .unlock,
:--group-light .unlock {
  --color: var(--dark);
  --color-rgb: var(--dark-rgb);
}

.unlock {
  margin-top: var(--section-gap);
}

.unlock__list {
  display: grid;
  align-items: flex-start;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
  margin-right: auto;
  margin-left: auto;
}
.unlock--count-1 .unlock__list {
  grid-template-columns: repeat(1, 1fr);
  max-width: 300px;
}
.unlock--count-2 .unlock__list {
  grid-template-columns: repeat(2, 1fr);
  max-width: 600px;
}
.unlock--count-3 .unlock__list {
  grid-template-columns: repeat(3, 1fr);
  max-width: 900px;
}
@media (--tablet) {
  .unlock__list {
    grid-template-columns: repeat(2, 1fr);
  }
  .unlock--count-1 .unlock__list {
    grid-template-columns: repeat(1, 1fr);
  }
  .unlock--count-2 .unlock__list {
    grid-template-columns: repeat(2, 1fr);
  }
  .unlock--count-3 .unlock__list {
    grid-template-columns: repeat(2, 1fr);
  }
}

.unlock__item {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (--mobile) {
  .unlock__item {
    width: 100%;
  }
}

.unlock__item__icon {
  --size: 32px;

  width: var(--size);
  height: var(--size);
}

.unlock__item__title {
  font-size: 20px;
  font-weight: 700;
  line-height: 140%;
  color: var(--color);
  text-align: center;
}
* + .unlock__item__title {
  margin-top: 12px;
}
@media (--mobile) {
  .unlock__item__title {
    font-size: 18px;
  }
  * + .unlock__item__title {
    margin-top: 8px;
  }
}

.unlock__item__content {
  font-size: 16px;
  font-weight: 500;
  line-height: 130%;
  color: var(--color);
  text-align: center;
}
* + .unlock__item__content {
  margin-top: 6px;
}
@media (--mobile) {
  .unlock__item__content {
    font-size: 15px;
  }
}
