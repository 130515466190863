.level-up {
  --vertical-padding: 100px;

  position: relative;

  padding-top: var(--vertical-padding);
  padding-bottom: var(--vertical-padding);
}

@media (--tablet) {
  .level-up {
    --vertical-padding: 60px;
  }
}

@media (--mobile) {
  .level-up {
    --vertical-padding: 40px;
  }
}

* + .level-up {
  border-top: 5px solid var(--primary-500);
}

.comparative-table + .level-up {
  padding-top: 0;
}

.level-up--without-border {
  border-top: none;
}

.level-up__gradient {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background: linear-gradient(
      130deg,
      rgba(0, 0, 0, 0.8) 16%,
      rgba(0, 0, 0, 0) 60%
    ),
    linear-gradient(123deg, rgba(0, 0, 0, 0.8) 14%, rgba(0, 0, 0, 0) 58%);
}

.level-up--without-gradient > .level-up__gradient {
  display: none;
}

.level-up .max-width {
  position: relative;
}

.level-up__title {
  max-width: 35ch;
  margin-right: auto;
  margin-left: auto;

  font-family: var(--font-family-title);
  font-size: var(--heading-font-size);
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 0.03em;

  color: var(--white);
  text-align: center;
}
.level-up__title strong {
  font-weight: 700;
  color: var(--primary-500);
}

.level-up__blocks {
  --gap: 24px;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--gap);

  list-style: none;
}

* + .level-up__blocks {
  margin-top: var(--spacing-56);
}

@media (--tablet) {
  .level-up__blocks {
    grid-template-columns: 1fr;
    max-width: 500px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media (--mobile) {
  .level-up__blocks {
    --gap: 20px;
  }
}

.level-up__blocks__item {
  --border-radius: 16px;

  position: relative;

  padding: var(--gap);

  border: 1px solid rgb(var(--white-rgb) 0);

  border-radius: var(--border-radius);
  background-image: linear-gradient(
      0deg,
      rgb(0, 0, 0 0.2) 0%,
      rgb(0, 0, 0 0.2) 100%
    ),
    linear-gradient(
      130deg,
      rgb(var(--white-rgb) 0.2) 0%,
      rgb(var(--white-rgb) 0.1) 100%
    );
  backdrop-filter: blur(12px);
}

.level-up__blocks__item::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  content: '';

  border: 1px solid transparent;
  border-radius: var(--border-radius);
  background: linear-gradient(
      135deg,
      rgb(255 255 255 / 30%),
      rgb(255 255 255 / 0%)
    )
    border-box;

  pointer-events: none;
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.level-up__blocks__item--title {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.level-up__blocks__item__icon {
  --size: 44px;

  display: block;
  width: var(--size);
  height: var(--size);
  margin-right: auto;
  margin-left: auto;
  object-fit: contain;

  user-select: none;
}

@media (--mobile) {
  .level-up__blocks__item__icon {
    --size: 36px;
  }
}

.level-up__blocks__item__title {
  max-width: 20ch;
  margin-right: auto;
  margin-left: auto;

  font-family: var(--font-family-title);
  font-size: 21px;
  font-weight: 800;
  line-height: 140%;
  letter-spacing: 0.03em;
  text-align: center;
}

.level-up__blocks__item__title strong {
  font-weight: 700;
  color: var(--primary-500);
}

.level-up__blocks__item__content {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 0.03em;
  color: var(--white);
  text-align: center;
}

.level-up__blocks__item__content b {
  color: var(--primary-500);
}

* + .level-up__blocks__item__content,
* + .level-up__blocks__item__title {
  margin-top: var(--gap);
}

.level-up__blocks__item__content * + p {
  margin-top: 12px;
}

* + .level-up__cta {
  margin-top: var(--spacing-56);

  text-align: center;
}
