.image-text__video,
.image-text__image {
  flex: 1;
  width: 100%;
}

.image-text__video {
  display: flex;
  align-items: center;
  width: auto;
}

.image-text__wrap__video__wrap {
  position: relative;

  overflow: hidden;

  border-radius: 8px;
  background-color: #efefef;
}

.image-text__video video {
  display: block;
  width: 100%;
  height: auto;
}

.image-text__wrap__video__play {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(var(--black-rgb), 0.3);
  transition: 200ms opacity;
}
.image-text__video--playing.image-text__video--started
  .image-text__wrap__video__play {
  opacity: 0;
}

.image-text__wrap__video__play img {
  --size: 100px;

  width: var(--size);
  height: var(--size);
}

.image-text__image {
  display: grid;
  gap: 12px;
  width: 100%;
  max-width: 540px;
  height: 100%;
  margin-right: auto;
  margin-left: auto;
  order: 1;
}
.image-text__image--single {
  display: flex;
}

.image-text__image--duo {
  position: relative;

  display: flex;
  height: 100%;
}

.image-text__wrap--reverse .image-text__image {
  display: grid;
  gap: 0;

  width: 100%;
  height: 100%;
  margin-left: 0;
  order: 0;
}
@media (--mobile) {
  .image-text__wrap--reverse .image-text__image {
    margin-left: auto;
    order: 1;
  }
}

.image-text__image img {
  display: block;
  width: 100%;
  height: auto;

  border-radius: 13px;
}
.image-text__image--single img {
  object-fit: contain;
}
.image-text__image--grid img {
  object-fit: cover;
}

.image-text__image--duo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.image-text__image--duo img:last-of-type {
  position: absolute;
}

.image-text__image img:first-child {
  grid-row: 1;
  grid-column: 1;
}
.image-text__image img:nth-child(2) {
  grid-row: 2;
  grid-column: 1;
}
.image-text__image img:last-child {
  grid-row: 1/3;
  grid-column: 2/3;
}
