.landing-form {
  --border-radius: 16px;
  --color: var(--white);
  --background: linear-gradient(
    280deg,
    rgba(255, 255, 255, 0.2) 30%,
    rgba(255, 255, 255, 0.04) 80%
  );
  --border: none;
}
.group--theme-grey-100 .landing-form {
  --color: var(--black);
  --background: rgba(255, 255, 255, 0.3);
  --border: 1px solid #d5d5d5;
}
.group--theme-white .landing-form {
  --color: var(--black);
  --background: linear-gradient(
    280deg,
    rgba(246, 221, 220, 0.48) 30%,
    rgba(246, 221, 220, 0.24) 100%
  );
  --border: 1px solid rgba(251, 112, 107, 0.42);
}

* + .landing-form,
.landing-form:first-child {
  margin-top: 64px;
}
.landing-testimonials + .landing-form {
  margin-top: var(--landing-testimonials-gap);
}
@media (--mobile) {
  * + .landing-form {
    margin-top: 32px;
  }
}

.landing-form__wrap {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  padding: 32px;

  border: var(--border);

  border-radius: var(--border-radius);
  background: var(--background);
}
@media (--tablet) {
  .landing-form__wrap {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
}
@media (--mobile) {
  .landing-form__wrap {
    gap: 16px;
    padding: 24px;
  }
}

.landing-form__wrap::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  content: '';

  border: 1px solid transparent;
  border-radius: var(--border-radius);
  background: linear-gradient(
      135deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.3) 30%,
      rgba(255, 255, 255, 0) 80%
    )
    border-box;

  pointer-events: none;
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.landing-form__title {
  font-size: 23px;

  font-weight: 600;
  line-height: 1.3;
  letter-spacing: 0.01em;
  color: var(--color);
}
@media (--mobile) {
  .landing-form__title {
    font-size: 20px;
  }
}
.landing-form__title strong {
  color: var(--primary-500);
}

@media (--tablet) {
  .landing-form__form {
    width: 100%;
    max-width: 500px;
  }
}
