.tutors,
:--layout-dark .tutors,
:--group-dark .tutors {
  --max-width: var(--page-max-width);
  --color: var(--white);
  --color-rgb: var(--white-rgb);
  --gradient-rgb: var(--black-rgb);
  --color-rgba: rgba(var(--white-rgb), 1);

  position: relative;

  overflow: hidden;
}
:--layout-light .tutors,
:--group-light .tutors {
  --color: var(--black);
  --color-rgb: var(--black-rgb);
  --gradient-rgb: var(--white-rgb);
  --color-rgba: rgba(var(--black-rgb), 0.5);
}

* + .tutors {
  margin-top: var(--section-gap);
}

.tutors__wrap {
  position: relative;

  width: 100%;
  max-width: calc(
    var(--page-max-width) + var(--page-left-padding) + var(--page-right-padding)
  );

  margin-right: auto;
  margin-left: auto;
  padding-right: var(--page-right-padding);
  padding-left: var(--page-left-padding);
}

.tutors::before,
.tutors::after {
  --direction: to right;
  position: absolute;
  top: 0;
  z-index: 1;

  content: '';
  display: block;
  width: calc((100vw - var(--max-width)) / 2);
  height: 100%;

  background-image: linear-gradient(
    var(--direction),
    rgb(var(--gradient-rgb)) 65%,
    rgba(255, 255, 255, 0)
  );
  pointer-events: none;
}

.tutors::before {
  left: 0;
}
.tutors::after {
  --direction: to left;

  right: 0;
}

.heading + .tutors {
  margin-top: 80px;
}
@media (--mobile) {
  .heading + .tutors {
    margin-top: 48px;
  }
}

.tutors__item {
  --width: 230px;
  --gap: 16px;
  --offset: 0px;
  --number: 5;
  --half-number: calc(var(--number) / 2);
  flex-basis: 220px;
  flex-basis: var(--width);

  flex-shrink: 0;
}
@media (--mobile) {
  .tutors__item {
    --width: 180px;
  }
}

.tutors__list.keen-slider:not([data-keen-slider-disabled]) {
  overflow: visible;
}

.tutors__item-box {
  display: flex;
  flex-direction: column;

  flex-shrink: 0;
  align-items: center;
}

.tutors__item__image {
  --size: 122px;

  width: var(--size);
  height: var(--size);
}

.tutors__item__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-fit: contain;
}

.tutors__item__image + .tutors__item__title {
  margin-top: 32px;
}

.tutors__item__title {
  position: relative;

  font-size: 17px;

  font-weight: 700;
  line-height: 130%;
  letter-spacing: 0.06em;
  text-align: center;
}
:--layout-dark .tutors__item__title,
:--group-dark .tutors__item__title {
  color: var(--primary-500);
}

:--layout-light .tutors__item__title,
:--group-light .tutors__item__title {
  color: var(--black);
}

.tutors__item__content {
  max-width: 22ch;

  font-size: 14px;

  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.08em;
  color: var(--color-rgba);

  text-align: center;
}

.tutors__item__content hr {
  width: 75%;
  height: 1px;
  margin-top: 12px;
  margin-right: auto;
  margin-bottom: 12px;
  margin-left: auto;

  border: 0;

  background-color: rgba(var(--color-rgb), 0.3);
}

.tutors__cta {
  margin-top: 48px;
}

.tutors__button {
  --offset: 40px;

  position: absolute;
  top: 50%;
  z-index: 2;

  display: flex;
}

.tutors__button__white {
  display: none;
}

.group--theme-white .tutors__button__white {
  display: none;
}

.group--theme-black .tutors__button__white {
  display: block;
}

.group--theme-white .tutors__button__black {
  display: block;
}

.group--theme-black .tutors__button__black {
  display: none;
}

.tutors__button--prev {
  right: 100%;
}
.tutors__button--next {
  left: 100%;
}

.tutors__dots {
  display: flex;
  justify-content: center;
  margin-top: 24px;
  padding: 10px 0;
}

.tutors__dot {
  width: 10px;
  height: 10px;
  margin: 0 5px;
  padding: 5px;

  border: none;
  border-radius: 50%;
  background: #e2e2e2;
  cursor: pointer;
}

.tutors__dot:focus {
  outline: none;
}

.tutors__dot.active {
  background: #000;
}

@media (--mobile) {
  .tutors__item {
    margin-left: 0;
  }
}
@media (--mobile) {
  .tutors__item:first-child,
  .tutors__item:last-child {
    --offset: 0px;
  }
}

@media (--mobile) {
  .tutors {
    padding-bottom: 0;
  }

  .tutors__item__image {
    --img-size: 100px;
  }

  .tutors__item__image + .tutors__item__title {
    margin-top: 24px;
  }
}

@media (--tablet) {
  .tutors__button {
    display: none !important;
  }
}
