.slider-ressources,
:--layout-dark .slider-ressources,
:--group-dark .slider-ressources {
  --color: var(--white);
  --color-rgb: var(--white-rgb);
  --color-shadow: var(--black);
  --color-shadow-rgb: var(--black-rgb);
}

:--layout-light .slider-ressources,
:--group-light .slider-ressources {
  --color: var(--black);
  --color-rgb: var(--black-rgb);
  --color-shadow: var(--white);
  --color-shadow-rgb: var(--white-rgb);
}

* + .slider-ressources {
  margin-top: var(--section-gap);
}
@media (--mobile) {
  .heading + .slider-ressources {
    margin-top: 24px;
  }
}

.slider-ressources {
  display: flex;
  margin-top: var(--section-gap);
}

.slider-ressources-content::before {
  display: none;
}

.slider-ressources-content::after {
  display: none;
}

@media (--wider-than-desktop) {
  .slider-ressources-content::before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;

    content: '';
    display: block;

    width: 100%;
    height: 100%;

    background: linear-gradient(
      90deg,
      var(--color-shadow) 0%,
      rgba(var(--color-shadow-rgb), 0.5) 3%,
      rgba(var(--color-shadow-rgb), 0) 8%
    );
  }

  .slider-ressources-content::after {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 5;

    content: '';
    display: block;

    width: 100%;
    height: 100%;

    background: linear-gradient(
      -90deg,
      var(--color-shadow) 0%,
      rgba(var(--color-shadow-rgb), 0.5) 3%,
      rgba(var(--color-shadow-rgb), 0) 8%
    );
  }
}

.slider-ressources__max-width {
  position: relative;

  width: 100%;

  margin-right: auto;
  margin-left: auto;
}

@media (--wider-than-desktop) {
  .slider-ressources__max-width {
    max-width: calc(
      1600px + var(--page-left-padding) + var(--page-right-padding)
    );
    padding-right: var(--page-right-padding);
    padding-left: var(--page-left-padding);
  }
}

.slider-ressources__item.keen-slider__slide {
  --width: 700px;

  flex: 0 0 var(--width);
  width: var(--width);

  border-radius: 8px;
  aspect-ratio: 7/3;
}
@media (--tablet) {
  .slider-ressources__item.keen-slider__slide {
    --width: 500px;
  }
}
@media (--mobile) {
  .slider-ressources__item.keen-slider__slide {
    --width: 280px;

    aspect-ratio: 3/5;
  }
}

.slider-ressources__item__picture {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (--mobile) {
  .slider-ressources__item__picture {
    width: 100%;
  }
}
