* + .landing-program {
  margin-top: 90px;
}
@media (--mobile) {
  * + .landing-program {
    margin-top: 32px;
  }
}

@media (--mobile) {
  .landing-program__desktop {
    display: none;
  }
}

.landing-program__desktop img {
  width: 100%;
  height: auto;
  object-fit: contain;
  pointer-events: none;
}

.landing-program__mobile {
  --offset: 40px;
  --height: 172px;

  display: flex;
  overflow-x: scroll;
  overflow-x: auto;
  gap: var(--offset);
  height: auto;
  min-height: calc(var(--height) + 8px);
  margin-right: calc(-1 * var(--page-right-padding));
  margin-left: calc(-1 * var(--page-left-padding));
  padding-right: calc(var(--page-right-padding) + var(--offset) / 2);
  padding-left: var(--page-left-padding);
  scroll-snap-type: x mandatory;
}
.landing-program__mobile::-webkit-scrollbar {
  display: none;
}
@media (--wider-than-mobile) {
  .landing-program__mobile {
    display: none;
  }
}

.landing-program__item {
  position: relative;

  flex-shrink: 0;
  width: 270px;
  height: var(--height);
  height: auto;
  padding: 24px 0 24px 24px;

  scroll-snap-align: center;
  scroll-margin: var(--page-horizontal-padding);
  transition: 200ms opacity;
}
.landing-program__item--theme-red {
  --color: #fb706b;
  --color-rgb: 251, 112, 107;
}
.landing-program__item--theme-blue {
  --color: #6096ff;
  --color-rgb: 96, 150, 255;
}
.landing-program__item--theme-green {
  --color: #5cf459;
  --color-rgb: 92, 244, 89;
}
.landing-program__item--theme-purple {
  --color: #a84af2;
  --color-rgb: 168, 74, 242;
}
.landing-program__item--theme-yellow {
  --color: #ffd231;
  --color-rgb: 255, 210, 49;
}
.landing-program__item--theme-light-blue {
  --color: #69bdf9;
  --color-rgb: 105, 189, 249;
}
.landing-program__item--theme-white {
  --color: var(--white);
  --color-rgb: var(--white-rgb);
}
@media (min-width: 390px) {
  .landing-program__item--unactive {
    opacity: 0;
  }
}

.landing-program__item__background {
  position: absolute;
  top: 0;
  right: calc(-1 * var(--offset));
  bottom: 0;
  left: calc(-1 * var(--offset));
  pointer-events: none;
}

.landing-program__item__background svg {
  width: 100%;
  height: auto;
  transition: 200ms opacity;
}
.landing-program__item--unactive .landing-program__item__background svg,
.landing-program__item--active .landing-program__item__background svg {
  opacity: 0;
}

.landing-program__item__background__plain {
  --border-radius: 8px;
  position: absolute;
  top: 0;
  right: 24px;
  bottom: 0;
  left: 24px;

  border-radius: var(--border-radius);
  background-image: linear-gradient(
    94deg,
    rgba(var(--color-rgb), 0.2) 0%,
    rgba(var(--color-rgb), 0.04) 100%
  );
  opacity: 0;
  transition: 200ms opacity;
}
.landing-program__item--active .landing-program__item__background__plain {
  opacity: 1;
}
.landing-program__item__background__plain::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  content: '';

  border: 1px solid transparent;
  border-radius: var(--border-radius);
  background: linear-gradient(
      130deg,
      rgba(255, 255, 255, 0) -10%,
      rgba(255, 255, 255, 0.2) 40%
    )
    border-box;

  pointer-events: none;
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.landing-program__item__date {
  position: relative;

  display: inline-block;
  padding: 2px 8px;

  font-size: 16px;
  font-weight: 700;
  line-height: 1.3;
  letter-spacing: 0.01em;

  color: var(--dark);
  text-transform: uppercase;

  border-radius: 999px;
  background-color: var(--color);
}

.landing-program__item__title {
  position: relative;

  font-size: 16px;
  font-weight: 500;
  line-height: 1.3;
  letter-spacing: 0.01em;
  color: var(--white);
}
* + .landing-program__item__title {
  margin-top: 12px;
}

.landing-program__item__toggle {
  display: flex;
  align-items: center;
  gap: 4px;

  font-size: 14px;
  font-weight: 500;
  line-height: 1.3;
  letter-spacing: 0.01em;
  color: var(--color);
  text-align: left;
}
.landing-program__item__toggle::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  content: '';
}
* + .landing-program__item__toggle {
  margin-top: 24px;
}

.landing-program__item__toggle svg {
  position: relative;
  top: 1px;
  transition: 200ms transform;
}
.landing-program__item--active .landing-program__item__toggle svg {
  transform: rotate(180deg);
}

.landing-program__item__content {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.3;
  letter-spacing: 0.01em;
  color: rgba(var(--white-rgb), 0.9);
}
* + .landing-program__item__content::before {
  content: '';
  display: block;
  height: 16px;
}
.landing-program__item--active .landing-program__item__content {
  animation: landing-program-item-content 500ms ease-in-out 0s 1 normal forwards;
}
@keyframes landing-program-item-content {
  from {
    opacity: 0;
    transform: translateY(-16px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.landing-program__item__content * + p {
  margin-top: 16px;
}

.landing-program__item__content ol {
  list-style: none;
  counter-reset: list;
}
.landing-program__item__content * + ol {
  margin-top: 16px;
}

.landing-program__item__content ol li {
  display: flex;
  align-items: center;
  gap: 16px;
  counter-increment: list;
}
.landing-program__item__content ol * + li {
  margin-top: 16px;
}

.landing-program__item__content ol li::before {
  --size: 28px;
  content: counter(list);

  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: var(--size);
  height: var(--size);

  font-size: 14px;
  font-weight: 800;
  line-height: 1.3;
  letter-spacing: 0.01em;

  color: var(--white);

  border-radius: 999px;
  background-color: var(--color);
  text-align: center;
}
