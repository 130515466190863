.module-list {
  overflow: hidden;
}

* + .module-list {
  margin-top: var(--section-gap);
}

.module-list__background {
  position: relative;

  padding-bottom: 50px;

  background: radial-gradient(
      71% 76% at 50% 121%,
      rgba(227, 104, 100, 12%) 0%,
      rgba(215, 156, 154, 0) 100%
    ),
    var(--dark);
}

.module-list__cards-container {
  display: flex;
  overflow-x: auto;
  gap: 0;

  color: var(--black);
}
.module-list--not-slider .module-list__cards-container {
  --columns: 3;
  --gap: 24px;

  display: grid;
  overflow: unset;
  grid-template-columns: repeat(var(--columns), 1fr);
  gap: var(--gap);
}
@media (--tablet) {
  .module-list--slider .module-list__cards-container {
    overflow: visible;
  }
  .module-list--not-slider .module-list__cards-container {
    --columns: 2;
  }
}
@media (--mobile) {
  .module-list--not-slider .module-list__cards-container {
    display: flex;
    overflow-x: scroll;
    width: 100vw;
    margin-top: 24px;
    margin-left: calc(-1 * var(--page-left-padding));
    padding-right: var(--page-right-padding);
    padding-left: var(--page-left-padding);
    scroll-snap-type: x mandatory;
  }
}

.module-list__cards-container::-webkit-scrollbar {
  display: none;
}

.module-list__cta {
  position: absolute;
  bottom: 0;
}
* + .module-list__cta {
  margin-bottom: 24px;
}
@media (--mobile) {
  * + .operating__cta {
    margin-top: 32px;
  }
}

.module-list__slider {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-top: 40px;
}
@media (--tablet) {
  .module-list__slider {
    display: none;
  }
}

.module-list__button {
  --offset: -50px;

  position: absolute;
  top: 50%;
  z-index: 1;
  transform: translateY(-50%);
}
.module-list__button--prev {
  left: var(--offset);
}
.module-list__button--next {
  right: var(--offset);
}

@media (max-width: 1333px) {
  .module-list__button {
    display: none;
  }
}

.module-list__dots {
  display: flex;
  justify-content: center;
  margin-top: 24px;
  padding: 10px 0;
}

.module-list__dot {
  width: 10px;
  height: 10px;
  margin: 0 5px;
  padding: 5px;

  border: none;
  border-radius: 50%;
  background: #e2e2e2;
  cursor: pointer;
}

.module-list__dot:focus {
  outline: none;
}

.module-list__dot.active {
  background: #000;
}

.module-list__mobile-first-card {
  display: none;
}

.module-list__cards-mobile-last-cards {
  display: none;
}
