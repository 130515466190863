.join,
:--layout-dark .join,
:--group-dark .join {
  --color: var(--white);
  --color-rgb: var(--white-rgb);
}
:--layout-light .join,
:--group-light .join {
  --color: var(--black);
  --color-rgb: var(--black-rgb);
}

.join:first-child,
* + .join {
  margin-top: var(--section-gap);
}

.join {
  color: var(--color);
}

.join__max-width {
  --page-max-width: 980px;

  display: flex;
  align-items: flex-start;
  gap: 32px;
}
.join--success .join__max-width {
  align-items: stretch;
}
@media (--tablet) {
  .join__max-width {
    gap: 24px;
  }
}
@media (--mobile) {
  .join__max-width {
    flex-direction: column-reverse;
    gap: 16px;
  }
}
