.line-formations,
:--layout-dark .line-formations,
:--group-dark .line-formations {
  --color: var(--white);
  --color-rgb: var(--white-rgb);
}
:--layout-light .line-formations,
:--group-light .line-formations {
  --color: var(--black);
  --color-rgb: var(--black-rgb);
}

* + .line-formations,
.line-formations:first-child {
  margin-top: var(--section-gap);
}

.line-formations__line {
  width: 75%;
  height: 1px;
  margin-right: auto;
  margin-left: auto;

  background-image: repeating-linear-gradient(
    to right,
    rgb(56, 56, 56),
    rgb(49, 49, 49) 5px,
    transparent 5px,
    transparent 10px
  );
}
@media (--tablet) {
  .line-formations__line {
    display: none;
  }
}

.line-formations__dots {
  display: flex;
  justify-content: space-around;
  margin-top: -5px;
}
@media (--tablet) {
  .line-formations__dots {
    display: none;
  }
}

.line-formations__dot {
  width: 10px;
  height: 10px;

  border-radius: 50%;
  background-color: black;
}
.line-formations--colored-dots .line-formations__dot {
  background-color: var(--primary-500);
}

.line-formations__wrap {
  --gap: 32px;

  display: flex;
  justify-content: center;
  gap: var(--gap);
  margin-top: 48px;
  list-style: none;
}
@media (--tablet) {
  .line-formations__wrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (--mobile) {
  .line-formations__wrap {
    display: flex;
    flex-direction: column;
  }
}

.line-formations__card {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: calc((100% / var(--nb-items)));
  max-width: 300px;
  margin-right: auto;

  margin-left: auto;
}
@media (--tablet) {
  .line-formations__card {
    width: auto;
    max-width: unset;
  }
}
@media (--mobile) {
  .line-formations__card {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
  }
}

.line-formations__card__image {
  position: relative;

  display: flex;
  overflow: hidden;
  width: 100%;
  max-height: 172px;

  border-radius: 12px;
}

.line-formations__card--use-icon .line-formations__card__image {
  overflow: unset;
  width: unset;
}

.line-formations__card--use-icon .line-formations__card__image img {
  display: block;
  width: unset;
  width: 32px;
  height: 32px;
  margin: 0 auto;
}

@media (--mobile) {
  .line-formations__card__image {
    max-width: unset;

    max-height: 200px;
  }
}

.line-formations__card__image img {
  width: 100%;
  height: auto;

  object-fit: cover;
}

.line-formations__card__title {
  margin-top: 24px;

  font-size: 20px;
  font-weight: 700;
  line-height: 140%;
  color: var(--color);
}

.line-formations--center-text .line-formations__card__title {
  text-align: center;
}

@media (--tablet) {
  .line-formations__card__title {
    margin-top: 16px;
  }
}

.line-formations__card__content {
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
  color: rgba(var(--color-rgb), 0.8);
}
.line-formations--center-text .line-formations__card__content {
  text-align: center;
}
* + .line-formations__card__content {
  margin-top: 8px;
}
