.join__left {
  height: auto;
  padding: 0 32px;
}
.join--success .join__left {
  flex: 1 0 auto;
}
@media (--tablet) {
  .join__left {
    padding: 0;
  }
}
@media (--mobile) {
  .join__left {
    width: 100%;
  }
}

.join__title {
  font-size: 24px;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: 0.01em;
}
@media (--tablet) {
  .join__title {
    font-size: 20px;
  }
}
@media (--mobile) {
  .join__title {
    font-size: 16px;
  }
}

.join__form {
  --gap: 8px;
}
* + .join__form {
  margin-top: 32px;
}
@media (--tablet) {
  * + .join__form {
    margin-top: 16px;
  }
}

.join__line {
  display: flex;
  gap: var(--gap);
}
* + .join__line {
  margin-top: var(--gap);
}

.join__line > .field {
  flex: 1;
}

.join__cta {
  --height: 42px;
  width: 100%;
  text-align: center;
}
* + .join__cta {
  margin-top: 32px;
}
@media (--mobile) {
  * + .join__cta {
    margin-top: 24px;
  }
}

.join__cta .button__text {
  font-size: 14px;
  font-weight: 600;
}

.join__success {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 30ch;
  height: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 32px 0;

  font-size: 20px;
  line-height: 1.6;

  text-align: center;
}
* + .join__success {
  margin-top: 24px;
}
@media (--mobile) {
  .join__success {
    font-size: 16px;
  }
}

.join__error {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.3;
  color: var(--primary-500);
}
* + .join__error {
  margin-top: 12px;
}

.join__notice {
  font-size: 12px;
  font-weight: 300;
  line-height: 1.4;
  letter-spacing: 0.01em;
  color: rgba(var(--color-rgb), 0.6);
}
* + .join__notice {
  margin-top: 14px;
}

.join__notice * + p {
  margin-top: 1em;
}
