* + .landing-section {
  margin-top: 80px;
}
@media (--mobile) {
  * + .landing-section {
    margin-top: 32px;
  }
  .landing-section + .landing-section {
    margin-top: 48px;
  }
}

.landing-section__flex {
  display: flex;
  align-items: center;
  gap: 64px;
}
.landing-section--reverse .landing-section__flex {
  flex-direction: row-reverse;
}
@media (--tablet) {
  .landing-section__flex {
    gap: 32px;
  }
}
@media (--mobile) {
  .landing-section__flex {
    align-items: flex-start;
    gap: 16px;
  }
  .landing-section__flex,
  .landing-section--reverse .landing-section__flex {
    flex-direction: column;
  }
}

.landing-section__illustration {
  position: relative;

  flex: 60%;
  overflow: hidden;

  border-radius: 8px;
}
@media (--tablet) {
  .landing-section__illustration {
    flex: 50%;
  }
}
@media (--mobile) {
  .landing-section__illustration {
    width: 100%;
  }
}

.landing-section__illustration--inner-shadows::before,
.landing-section__illustration--inner-shadows::after {
  position: absolute;
  top: 0;

  content: '';
  width: 100px;
  height: 100%;

  background-image: linear-gradient(
    var(--direction),
    rgba(var(--white-rgb), 1),
    rgba(var(--white-rgb), 0)
  );
}
.landing-section__illustration--inner-shadows::before {
  --direction: to right;

  left: 0;
}
.landing-section__illustration--inner-shadows::after {
  --direction: to left;

  right: 0;
}

.landing-section__illustration > img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
@media (--mobile) {
  .landing-section__illustration > img {
    height: auto;
  }
}

.landing-section__illustration video {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.landing-section__text {
  flex: 44%;
}

.landing-section--content-center .landing-section__text {
  align-self: center;
}
@media (--tablet) {
  .landing-section__text {
    flex: 50%;
    margin-top: 0;
  }
}

.landing-section__title {
  font-size: 26px;

  font-weight: 600;
  line-height: 130%;
  letter-spacing: 0.01em;
  color: #000;
}
@media (--tablet) {
  .landing-section__title {
    font-size: 26px;
  }
}
@media (--mobile) {
  .landing-section__title {
    font-size: 20px;
  }
}

.landing-section__content {
  font-size: 16px;

  font-weight: 400;
  line-height: 130%;
  letter-spacing: 0.01em;
  color: #000;
  color: rgba(0, 0, 0, 70%);
}
* + .landing-section__content {
  margin-top: 40px;
}
@media (--mobile) {
  * + .landing-section__content {
    margin-top: 24px;
  }
}

.landing-section__content * + p {
  margin-top: 20px;
}

.landing-section__content b {
  font-weight: 500;
  color: #000;
}

* + .landing-section__form {
  margin-top: 32px;
}
@media (--mobile) {
  * + .landing-section__form {
    margin-top: 24px;
  }
}

* + .landing-section__cta {
  margin-top: 32px;
}
@media (--mobile) {
  * + .landing-section__cta {
    margin-top: 24px;
  }
}
