* + .horizontal-line {
  margin-top: 64px;
}

@media (--mobile) {
  * + .horizontal-line {
    margin-top: 32px;
  }
}

.horizontal-line__hr {
  height: 1px;
  margin-top: 48px;

  border: 0;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(143, 143, 143, 0.75),
    rgba(0, 0, 0, 0)
  );
}
