.slider-team,
:--layout-dark .slider-team,
:--group-dark .slider-team {
  --shadow-rgb: var(--black-rgb);
}
:--layout-light .slider-team,
:--group-light .slider-team {
  --shadow-rgb: var(--white-rgb);
}

* + .slider-team {
  margin-top: var(--section-gap);
}

.slider-team {
  display: flex;
  margin-top: var(--section-gap);
}

.slider-team__max-width {
  position: relative;

  width: 100%;
  max-width: 2000px;
  margin-right: auto;
  margin-left: auto;
}

.slider-team-content::before,
.slider-team-content::after {
  position: absolute;
  top: 0;
  z-index: 5;

  content: '';

  width: 64px;
  height: 100%;

  background: linear-gradient(
    var(--direction),
    rgba(var(--shadow-rgb), 1) 0%,
    rgba(var(--shadow-rgb), 0) 100%
  );
}
.slider-team-content::before {
  --direction: to right;

  left: 0;
}
.slider-team-content::after {
  --direction: to left;

  right: 0;
}
@media (max-width: 2000px) {
  .slider-team-content::before,
  .slider-team-content::after {
    display: none;
  }
}
