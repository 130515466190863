.module-list__item {
  position: relative;

  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  overflow: hidden;
  width: 340px;

  min-height: 470px;

  border-radius: 16px;

  background-color: #f3f4f8;
  scroll-margin: 30px;
}
@media (--mobile) {
  .module-list--slider .module-list__item {
    --width: calc(80vw - var(--page-left-padding) - var(--page-right-padding));

    flex-basis: var(--width);
    width: var(--width);
  }
}

@media (--mobile) {
  .module-list--not-slider .module-list__cards-container .module-list__item {
    width: calc(80vw - var(--page-left-padding) - var(--page-right-padding));
    scroll-snap-align: start;
    scroll-margin: var(--page-left-padding);
  }
  .module-list--not-slider
    .module-list__cards-container
    .module-list__item--remove-from-mobile-slider {
    display: none;
  }
  .module-list__mobile-first-card {
    display: block;
  }
  .module-list__cards-mobile-last-cards {
    display: block;
  }
  * + .module-list__cards-mobile-last-cards {
    margin-top: 20px;
  }
  .module-list__cards-mobile-last-cards * + .module-list__item {
    margin-top: 20px;
  }
}

.module-list__item--theme-normal {
  --img-height: 218px;
}

.module-list__item--theme-airy,
.module-list__item--theme-full {
  justify-content: end;
}

.module-list--not-slider .module-list__item {
  width: 100%;
}

.module-list__item__program {
  font-size: 16px;
  line-height: 1.4;
  color: var(--primary-500);
  text-transform: uppercase;

  border-radius: 8px;
}
@media (--mobile) {
  .module-list__item__program {
    font-size: 15px;
  }
}

.module-list__item--not-published .module-list__item__program {
  color: var(--black);
}

.module-list__item__plus-sign {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 4;
}

.module-list__item__image {
  position: relative;
  z-index: 1;

  overflow: hidden;

  height: var(--img-height);
}
@media (--mobile) {
  .module-list__item--theme-normal .module-list__item__image {
    height: 0;
    padding-bottom: 60%;
  }
  .module-list__item--theme-normal .module-list__item__image img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.module-list__item--theme-airy .module-list__item__image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  max-height: unset;
}

.module-list__item--theme-full .module-list__item__image {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;

  height: 70%;

  max-height: unset;
}

.module-list__item--theme-full::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;

  content: '';

  max-height: unset;

  background: linear-gradient(
    180deg,
    rgba(var(--black-rgb), 0) 0%,
    rgba(var(--black-rgb), 1) 70%
  );
}
.module-list__item--theme-full.module-list__item--shadow-white::after {
  background: linear-gradient(
    180deg,
    rgba(243, 244, 248, 0) 0%,
    rgba(243, 244, 248, 1) 70%
  );
}

.module-list__item__image img {
  width: 100%;
  height: 100%;

  border-radius: 16px 16px 0 0;
  object-fit: cover;
}

.module-list__item__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 100%;
  padding: 24px;

  box-sizing: border-box;
}
.module-list__item--theme-normal .module-list__item__content {
  flex: 1;
  height: calc(100% - var(--img-height));
}
.module-list__item--theme-airy .module-list__item__content,
.module-list__item--theme-full .module-list__item__content {
  position: relative;
  z-index: 4;

  justify-content: end;
}
@media (--mobile) {
  .module-list__item__content {
    padding: 16px;
  }
}

.module-list__item__content__wrap {
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 100%;
}
* + .module-list__item__content__wrap {
  margin-top: 14px;
}
@media (--mobile) {
  .module-list__item__content__wrap {
    gap: 8px;
  }
  * + .module-list__item__content__wrap {
    margin-top: 12px;
  }
}

.module-list__item__content__wrap__title {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.4;
  color: #272830;
}
@media (--mobile) {
  .module-list__item__content__wrap__title {
    font-size: 18px;
  }
}

.module-list__item__content__wrap__title a::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 6;

  content: '';
  display: flex;
}

.module-list__item__content__wrap__title a {
  font-size: inherit;

  font-weight: 700;
  line-height: 140%;
  letter-spacing: 0.005em;
  color: #272830;
  text-decoration: none;
}

.module-list__item__content__wrap__description {
  display: flex;
  flex-direction: column;
  gap: 6px;

  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  color: rgba(0, 0, 0, 0.6);
}
@media (--mobile) {
  .module-list__item__content__wrap__description p {
    display: -webkit-box;
    overflow: hidden;

    font-size: 15px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}

.module-list__item__content__wrap__description b {
  font-size: 20px;
  font-weight: 500;
  line-height: 130%; /* 26px */
  letter-spacing: 0.01em;
  color: var(--primary-500);
  text-transform: uppercase;
}

.module-list__item__content__wrap__description p + p {
  margin-top: 20px;
}
@media (--mobile) {
  .module-list__item__content__wrap__description p + p {
    margin-top: 12px;
  }
}

.module-list__item__content__wrap__price-block {
  display: flex;
  gap: 12px;
}
@media (--mobile) {
  .module-list__item__content__wrap__price-block {
    flex-direction: column;
  }
}

.module-list__item__content__wrap__price {
  font-size: 20px;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: 0.01em;
  color: var(--black);
}
.module-list__item__content__wrap__price sub {
  bottom: unset;

  font-size: 16px;
}

.module-list__item__content__wrap__price-tag {
  position: relative;
  top: 2px;

  padding: 4px 7px 6px 7px;

  font-size: 14px;
  color: white;

  border: 1px solid #ff5c57;
  border-radius: 8px;
  background-color: var(--primary-500);
}
@media (--mobile) {
  .module-list__item__content__wrap__price-tag {
    top: 0;

    width: fit-content;
    max-width: 100%;
  }
}

.module-list__item--disabled .module-list__item__content__wrap__title a,
.module-list__item--disabled .module-list__item__content__wrap__title,
.module-list__item--disabled .module-list__item__content__wrap__description,
.module-list__item--disabled .module-list__item__content__wrap__description b,
.module-list__item--disabled .module-list__item__content__wrap__price {
  color: #8e8e8e;
}

.module-list__item--theme-airy .module-list__item__content__wrap__title a,
.module-list__item--theme-airy .module-list__item__content__wrap__title,
.module-list__item--theme-airy .module-list__item__content__wrap__description,
.module-list__item--theme-airy .module-list__item__content__wrap__description b,
.module-list__item--theme-airy .module-list__item__content__wrap__price,
.module-list__item--theme-full .module-list__item__content__wrap__title a,
.module-list__item--theme-full .module-list__item__content__wrap__title,
.module-list__item--theme-full .module-list__item__content__wrap__description,
.module-list__item--theme-full .module-list__item__content__wrap__description b,
.module-list__item--theme-full .module-list__item__content__wrap__price {
  color: white;
}
.module-list__item--theme-full.module-list__item--shadow-white
  .module-list__item__content__wrap__title
  a,
.module-list__item--theme-full.module-list__item--shadow-white
  .module-list__item__content__wrap__title,
.module-list__item--theme-full.module-list__item--shadow-white
  .module-list__item__content__wrap__description,
.module-list__item--theme-full.module-list__item--shadow-white
  .module-list__item__content__wrap__description
  b,
.module-list__item--theme-full.module-list__item--shadow-white
  .module-list__item__content__wrap__price {
  color: black;
}

.module-list__item__content__text {
  font-size: 16px;

  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0.05em;
  color: #272830;
}

* + .module-list__item__content__wrap__bottom {
  margin-top: 12px;
}

.module-list__item--theme-normal .module-list__item__content__wrap__bottom {
  display: flex;
  flex-direction: column;
  justify-content: end;
  height: 100%;
}

* + .module-list__item__content__button {
  margin-top: 20px;
}
@media (--mobile) {
  * + .module-list__item__content__button {
    margin-top: 12px;
  }
}

.module-list__item__content__button .button {
  width: 100%;

  text-align: center;
}
.module-list__item--disabled .button {
  cursor: default;
  opacity: 0.5;
}
.module-list__item--theme-normal:not(.module-list__item--disabled):hover
  .button {
  --background-color: var(--black);
  --color: var(--white);
}

.module-list__item--theme-full:not(.module-list__item--disabled):hover .button {
  --background-color: var(--white);
  --color: var(--black);
}
