.quote,
:--layout-dark .quote,
:--group-dark .quote {
  --color: var(--white);
  --color-rgb: var(--white-rgb);
}
:--layout-light .quote,
:--group-light .quote {
  --color: var(--black);
  --color-rgb: var(--black-rgb);
}

.quote {
  text-align: center;
}
* + .quote {
  margin-top: var(--section-gap);
}
@media (--mobile) {
  .heading + .quote,
  .horizontal-line + .quote {
    margin-top: 32px;
  }
}

.heading .quote {
  margin-top: var(--section-gap);
}

.quote__wrap {
  display: flex;
  flex-direction: column;
}

.quote__icon {
  --size: 32px;
  --offset: 16px;
  --color: var(--primary-500);

  position: absolute;

  display: inline;
  width: var(--size);
  height: var(--size);
  transform: translateX(-100%)
    translate(calc(-1 * var(--offset)), calc(-1 * var(--offset)));
}
.quote__icon--reverse {
  transform: translate(var(--offset), var(--offset)) rotate(180deg);
}
@media (--mobile) {
  .quote__icon {
    --size: 24px;
    --offset: 8px;
  }
}

.quote__text {
  max-width: 40ch;
  margin-right: auto;
  margin-left: auto;

  font-size: 32px;
  font-weight: 500;
  line-height: 1.5;
}
.quote__text p {
  display: inline;

  color: var(--color);
}
.quote__text b {
  font-weight: 600;
  color: var(--primary-500);
}
.quote__text * + p {
  margin-top: 24px;
}
@media (--tablet) {
  .quote__text {
    font-size: 24px;
  }
}
@media (--mobile) {
  .quote__text {
    padding-right: 24px;
    padding-left: 24px;

    font-size: 18px;
  }
  .quote__text br {
    display: none;
  }
}

.quote__image {
  --size: 80px;

  width: var(--size);
  height: var(--size);
  margin-right: auto;
  margin-left: auto;

  border-radius: 50%;
  object-fit: cover;
}
* + .quote__image {
  margin-top: 32px;
}

.quote__author {
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  color: var(--color);
}
* + .quote__author {
  margin-top: 14px;
}

.quote__role {
  font-size: 16px;
  font-weight: 500;
  line-height: 150%;
  color: rgba(var(--color-rgb), 0.5);
}
* + .quote__role {
  margin-top: 8px;
}
